<template>
  <modal v-model="showModal" dimmer maskClose :hideFooter="false" :okText="okText" :cancelText="cancelText" @on-ok="onOk" @on-cancel="onCancel">
    <div class="confirm-tip-panel" @click="handleClick">
      <div class="c-title">
        <span v-if="tipType==='warning'" class="waring fa fa-warning"></span>
        <span v-if="tipType==='success'" class="success fa fa-check"></span>
        <span class="text" v-html="title"></span>
      </div>
      <div class="c-body">
        <span class="text" v-html="text"></span>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
import YSelect from '../common/select/select'
export default {
  components: { Modal, YSelect },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    tipType: {
      type: String,
      default: 'warning' // success
    },
    okText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    }
  },
  data () {
    return {
      showModal: this.show
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    onOk () {
      this.$emit('on-ok')
      this.showModal = false
    },
    onCancel () {
      this.$emit('on-cancel')
      this.showModal = false
    },
    handleClick () {
      //
    }
  }
}
</script>
<style lang="less">
.confirm-tip-panel {
  padding-left: 60px;
  padding-right: 60px;
  background-color: #fff;
  border-radius: 4px;
  max-width: 550px;
  margin-top: 15px;
  margin-bottom: 10px;
  .c-title {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }
  .waring {
    color: rgb(255, 209, 58);
    margin-right: 0.5rem;
  }
  .success {
    color: #14c358;
    font-size: 18px;
    margin-right: 0.5rem;
  }
}
.tip01 {
  font-size: 14px;
}
.tipfoot01 {
  margin: 5px 0;
  color: #b60000;
}
</style>
