<template>
  <modal v-model="showModal" dimmer maskClose :hideFooter="false" okText="提交" cancelText="取消" @on-ok="onOk" @on-cancel="onCancel">
    <div class="email-tip">
      <span class="tip">填写邮箱接收他人应助，<a style="color:red;">提交后请到自己邮箱点击链接验证</a></span>
      <div style="padding-top:5px;">
        <span class="require">Email:</span>
        <input type="text" v-model="email">
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: this.show,
      email: ''
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      } else {
        this.email = ''
      }
    }
  },
  methods: {
    onOk () {
      let str = this.email || ''
      str = str.replace(/\s/g, '')
      if (this.emailValidate(str)) {
        this.$emit('on-ok', str)
        this.showModal = false
      }
    },
    onCancel () {
      this.showModal = false
    },
    emailValidate (str) {
      return /^(\w)+(-|\.|\w)*@(\w)+((\.\w+)+)$/.test(str)
    }
  }
}
</script>
<style lang="less">
.email-tip {
  padding: 30px 50px 10px 50px;
  .tip {
    color:#2a6aca;
    font-size:15px;
    display: inline-block;
    width: 250px;
  }
  input {
    margin-top: 5px;
    margin-left: 2px;
    width: 200px;
  }
  .require {
    position: relative;
    margin-right: 2px;
    &::before {
      content: "*";
      color: red;
      position: absolute;
      display: block;
      top: 0;
      left: -5px;
    }
  }
}
</style>
