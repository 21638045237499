<template>
  <div class="page-box search-line">
    <div class="search-left">
      <img src="../../assets/imgs/pmop60.png" alt=""/>
    </div>
    <div class="search-right" v-clickoutside="handleClose">
      <span class="search-input">
        <input type="text" v-model="term" @keyup.enter="handleSearch" @focus="handleMouseEnter" @blur="handleMouseLeave">
        <div class="search-list"></div>
        <span class="exchange" v-show="showTransfer" @click.stop="transfer"><img src="../../assets/imgs/transfer.png" alt=""></span>
        <span class="search-clear" v-show="showClear" @click.stop="clearTerm">
          <span class="clear-box-inner">
            <a class="clear-btn"></a>
          </span>
        </span>
      </span>
      <div class="button" @click.stop="handleSearch"><div class="search-icon"></div></div>
      <router-link class="search-ad-btn" :to="{name: 'paperSearchAdvance'}">高级检索</router-link>
      <ul class="search-history" v-show="showHistory">
        <li v-for="item in historyList" :key="item.id" @click.stop="handleHistoryClick(item)">{{item.term}}</li>
      </ul>
    </div>
  </div>
</template>
<script>
import clickoutside from '../common/directives/clickoutside'
import transMethod from './mixins/translate'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  mixins: [transMethod],
  directives: { clickoutside },
  props: {
    sTerm: String
  },
  data () {
    return {
      term: '',
      showClear: false,
      paperList: [],
      hasChinese: new RegExp(/[\u4e00-\u9fa5]+/),
      isChinese: new RegExp(/^[\u4e00-\u9fa5\s,，]+$/),
      showTransfer: false,
      showHistory: false,
      historyList: [],
      termParent: false // 标记从父组件传来
    }
  },
  computed: {
    currentRouteName () {
      return this.$store.getters.getCurrentRouteName
    }
  },
  watch: {
    sTerm (val) {
      if (val) {
        this.term = val
        this.termParent = true
      }
    },
    term (val) {
      if (val) {
        this.showClear = true
        if (this.termParent) {
          this.termParent = false
        } else {
          this.showHistory = true
        }
        if (this.hasChinese.test(val)) {
          this.showTransfer = true
        } else {
          this.showTransfer = false
          this.$emit('on-term', val)
        }
      } else {
        this.showClear = false
        this.showHistory = false
        this.showTransfer = false
        this.$emit('on-term', '')
      }
    }
  },
  mounted () {
    this.loadHistoryList()
  },
  methods: {
    clearTerm () {
      this.term = ''
    },
    handleSearch () {
      if (!this.hasChinese.test(this.term)) {
        this.$emit('on-search', this.term)
        this.showHistory = false
      }
    },
    transfer () {
      this.translate(this.term, en => {
        this.term = en
      })
    },
    loadHistoryList () {
      this.$http.get(`${this.httpRoot}/search/history/recent`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.historyList = res.historyList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleHistoryClick (item) {
      this.term = item.term
      this.$emit('on-search', item.term)
      setTimeout(() => {
        this.showHistory = false
      }, 100)
    },
    handleClose () {
      setTimeout(() => {
        this.showHistory = false
      }, 100)
    },
    handleMouseEnter () {
      this.showHistory = true
    },
    handleMouseLeave () {
      this.handleClose()
    }
  }
}
</script>
<style lang="less">
.search-line {
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
}
.search-left {
  padding-left: 1rem;
  margin-top: 0.125rem;
  margin-right: 3rem;
  img {
    width: 206px;
    height: 40px;
  }
}
.search-input {
  display: inline-block;
  vertical-align: top;
  position: relative;
  input {
    display: inline-block;
    max-width: none;
    border: 0.5px solid #aeb0b5;
    border-right: 0;
    padding-right: 3rem;
    padding-left: 0.75rem;
    height: 2.5rem;
    width: 30rem;
    font-size: 1rem;
    outline: 0;
    transition: box-shadow 0.15s;
    &:focus {
      border-color: #205493;
    }
  }
  .search-clear {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    height: 2.5rem;
    box-shadow: -0.4rem 0 0.6rem -0.3rem rgba(0,0,0,0.1);
  }
  .clear-box-inner {
    display: flex;
    padding: 0.5rem;
  }
}
.search-right {
  position: relative;
  padding-right: 1rem;
  display: flex;
  justify-content: flex-end;
  &> .button {
    position: relative;
    background-color: #0071bc;
    transition: background-color 0.15s;
    font-size: 1.125rem;
    border: none;
    color: #fff;
    height: 2.55rem;
    line-height: 2.55rem;
    width: 8.6rem;
    cursor: pointer;
  }
  &> .search-ad-btn {
    position: absolute;
    left: 0;
    top: 2.7rem;
    font-size: 12px;
  }
}
.search-icon {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid #fff;
  border-radius: 50%;
  position: relative;
  margin: 0.5rem auto 0 auto;
  &:after {
    content: ' ';
    position: absolute;
    background: #fff;
    width: 0.8rem;
    height: 0.1rem;
    top: 1.3rem;
    left: 0.9rem;
    transform: rotate(45deg);
  }
}
.exchange {
  position: absolute;
  display: block;
  top: 0.5rem;
  right: 3.5rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.search-history {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 2.6rem;
  width: 33.8rem;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,0.25);
  max-height: 400px;
  overflow-y: auto;
  li {
    list-style: none;
    cursor: pointer;
    padding: 5px 7px;
    &:hover {
      background: #f1f1f1;
      color: #0076ff;
    }
  }
}
@media screen and (max-width: 450px) {
  .search-left {
    display: none;
  }
  .search-input > input {
    width: 100px;
  }
}
@media screen and (max-width: 600px) and (min-width: 450px) {
  .search-left {
    display: none;
  }
  .search-input > input {
    width: 150px;
  }
}
@media screen and (max-width: 750px) and (min-width: 600px) {
  .search-left {
    display: none;
  }
  .search-input > input {
    width: 200px;
  }
}
@media screen and (max-width: 850px) and (min-width: 750px) {
  .search-input > input {
    width: 250px;
  }
}
@media screen and (max-width: 950px) and (min-width: 850px) {
  .search-input > input {
    width: 350px;
  }
}
@media screen and (max-width: 1050px) and (min-width: 950px) {
  .search-input > input {
    width: 400px;
  }
}
</style>
