<template>
  <modal v-model="showModal" transparent :top="top" :left="left" maskClose moveable title="论文引文格式">
    <div class="citation-panel">
      <textarea ref="text" cols="45" rows="12" :value="citation"></textarea>
    </div>
    <div class="citation-footer">
      <span class="copy" @click.stop="onCopy">
        <span class="copy-icon fa fa-copy"></span>
        <span>复制</span>
      </span>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    citation: String,
    top: {
      type: Number,
      default: 0
    },
    left: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      showModal: this.show
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    onCopy () {
      this.$refs.text.select()
      document.execCommand('copy')
      this.$refs.text.select()
    }
  }
}
</script>
<style lang="less">
.citation-panel {
  padding: 10px 15px 0 15px;
}
.citation-footer {
  padding: 0 20px 15px 15px;
  float: right;
  display: flex;
  height: 32px;
  line-height: 32px;
}
.citation-footer .copy {
  cursor: pointer;
  &:hover {
    color: #112e51;
  }
}
</style>
